<template>
    <div id="content">
        <div class="card">
            <h3 class="card_title">
                <indicator class="marginAuto" :condition="!verifiedSteps.tools" required="1" :ttle="verifiedSteps.tools"></indicator>
                Verwendete Werkzeuge
            </h3>
            <div class="padding radius" id="toolContainer">
                <div class="box" id="toolList">
                    <p><b>Werkzeuge</b></p>
                    <div class="indent pointer padding_small flex fitContent radius dark" @click="tool_selected = t" :class="{blue: tool_selected == t}" v-for="(tool, t) in toolData" :key="t">
                        <img :src="image(t)" alt="">
                        <p><b>{{dict(t)}}</b></p>
                    </div>
                </div>
                <div class="box" id="toolInformation" v-if="tool_selected && toolData[tool_selected]">
                    <p><b>{{dict(tool_selected)}}</b></p>
                    <div id="infoGrid" class="indent">
                        <p>Werkzeugpreis:</p>
                        <input type="number" :value="toolData[tool_selected].tool_price" @input="setToolValue('tool_price', $event.target.value)">
                        <p>€/Schneide</p>
                        <p>Standzeit:</p>
                        <input class="textspacing" type="number" :value="toolData[tool_selected].tool_life" @input="setToolValue('tool_life', $event.target.value)">
                        <p>min/Schneide</p>
                        <p>Gesamt-Schnittzeit:</p>
                        <p>{{toolData[tool_selected].time_transformed}}</p>
                        <p>{{toolData[tool_selected].unit}}</p>
                        <p>Werkzeugbedarf:</p>
                        <p><b>{{tool_demand}}</b></p>
                        <p>Schneiden</p>
                        <p>Werkzeugkosten:</p>
                        <p><b>{{tool_cost}}</b></p>
                        <p>€</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Tools",
    data() {
        return {
            tool_selected: 0
        }
    },
    methods: {
        dict(c) {
            return this.$store.state.dict[c] || c
        },
        image(tool) {
            let images = {
                roughing: "https://primecalc.de/assets/icons/tools/C.png",
                finishing: "https://primecalc.de/assets/icons/tools/V.png",
                partoff: "https://primecalc.de/assets/icons/tools/N.png",
                grooving: "https://primecalc.de/assets/icons/tools/N.png",
                drilling: "https://primecalc.de/assets/icons/tools/bohrer.png",
                insideturning: "https://primecalc.de/assets/icons/tools/V.png",
                milling: "https://primecalc.de/assets/icons/tools/endmill.png",
                threading: "https://primecalc.de/assets/icons/tools/M.png"
            }
            if(images[tool]) return images[tool]
            return "https://kalkulation.primecalc.de/assets/images/icons/tools.svg"
        },
        setToolValue(key, value, tool=this.tool_selected) {
            this.$store.commit("setToolValue", [this.$store.state, tool, key, value])
            this.$store.dispatch("calculate")
        }
    },
    computed: {
        toolData() {
            let data = {}
            let toolData = this.$store.getters.tools;
            for (const key in toolData) {
                if(toolData[key].used == 0 || toolData[key].time == 0) continue
                data[key] = toolData[key]
                if(toolData[key].time < 120) {
                    data[key].time_transformed = parseFloat(toolData[key].time).toFixed(0)
                    data[key].unit = "min"
                }
                else {
                    data[key].time_transformed = parseFloat(toolData[key].time/60).toFixed(1)
                    data[key].unit = "h"
                }
            }
            return data
        },
        tool_demand() {
            let tool = this.toolData[this.tool_selected]
            return Math.ceil(tool.time/tool.tool_life)
        },
        tool_cost() {
            let tool = this.toolData[this.tool_selected]
            return (tool.time/tool.tool_life*tool.tool_price).toFixed(2)
        },
        verifiedSteps() {
            return this.$store.getters.verifiedSteps
        }
    }
}
</script>

<style scoped>
    #toolList>div{
        margin-bottom: 4px;
        display: flex;
    }
    #toolList>div>p{
        margin: auto .25em auto 0;
    }
    #toolContainer{
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap: 3em;
    }
    #infoGrid{
        display: grid;
        grid-template-columns: auto 5em auto;
        grid-column-gap: .5em;
        grid-row-gap: 4px;
    }
    #infoGrid>*:nth-child(3n+2) {
        text-align: right;
    }
</style>